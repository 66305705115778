<template>
  <div>
    <NavigationBar name="邀请页面">
    </NavigationBar>
    <div class="content">
      <div class="img-content">
        <img class="bg-img" :src="bgImg" width="100%">
      </div>
      <div class="link-content">
        <div>邀请成员：</div>
        <div v-show="copySuccess" class="flexbox">
          <md-icon name="success" size="lg" color="#41b883"></md-icon>
          <p class="tips">复制成功，请粘贴发送</p>
        </div>

        <div class="flexbox">
          <input ref="copyInput" class="link-input" type="text" disabled v-model="linkUrl">
          <md-button type="primary" size="small" plain @click="copyLink">复制链接</md-button>
          <button type="button"
                  ref="copy"
                  v-show="false"
                  v-clipboard:copy="linkUrl"
                  v-clipboard:success="onCopy">
            Copy!
          </button>
        </div>
      </div>
      <p id="tip">复制链接并通过微信发送到您单位的成员。</p>
    </div>
  </div>

</template>

<script>
    import {Button, Icon} from 'mand-mobile'

    export default {
        name: "InviteLink",
        components: {
            [Button.name]: Button,
            [Icon.name]: Icon
        },
      mounted() {
          console.log(this.$store.state.userInfo)
      },
      data() {
            return {
                bgImg: require('@/assets/inviteImages/background.png'),
                linkUrl:location.protocol+'//'+location.host+'/#/submitForm?collage_id='+this.$store.state.userInfo.collage_id,
                copySuccess: false
            }
        },
      computed: {
        userInfo() {
          return this.$store.state.userInfo
        }
      },
        methods: {
            copyLink() {
                this.$refs.copy.click()
            },
            onCopy() {
                this.copySuccess = true
            }
        }
    }
</script>

<style scoped>
.img-content {
    padding: 0 5%;
    position: relative;
    z-index: 1;
}
.bg-img {
    margin-top: 60px;
}
.link-content {
    margin: 0 2%;
    padding:50px 35px;
    box-shadow: 0 4px 8px 0 rgba(50, 50, 50, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    background-color: white;
    margin-top: -120px;
    position: relative;
    z-index: 2;
    font-size: 35px;
}
.tips {
    margin: 3px 0 0 10px;
}
.flexbox {
    display:flex;
    margin-top: 40px;
}
.link-input {
    width: 230%;
    height: 52px;
    margin-right: 20px;
    text-indent: 15px;
    color: rgb(175, 175, 175);
}
#tip{
  font-size: 30px;
  text-align: center;
  margin-top: 40px;
}
</style>
